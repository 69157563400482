import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { graphql } from "gatsby";
import Flex from "../components/containers/flex";

import SEO from "../components/globals/seo";
import Section from "../components/containers/section";
import Box from "../components/containers/box";
import Image from "../components/globals/image";
import Container from "../components/containers/container";
import Layout from "../components/containers/layout";
import Heading from "../components/typography/heading";
import TextWithLink from "../components/links/textWithLink";
import { Absolute, Relative } from "../components/containers/position";
import MaxWidthAbsolute from "../components/containers/maxWidthAbsolute";
import Text from "../components/typography/text";
import AppStoreLinks from "../components/links/appstoreLinks";
import Circle from "../components/globals/circle";
import Video from "../components/video/video";

import Carousel from "../components/carousel/carousel";
import CarouselItem from "../components/carousel/carouselItem";
import CarouselQuote from "../components/carousel/carouselQuote";
import CarouselButton from "../components/carousel/carouselButton";
import Counter from "../components/carousel/counter";
import ProfileComponent from "../components/profile/profile";
import useCarousel from "../hooks/useCarousel";

const Profile = styled(ProfileComponent)`
  position: absolute;
  z-index: 5;

  @media (min-width: ${p => p.theme.screenM}) {
    top: 90%;
    left: 7%;
  }
  @media (min-width: 1400px) {
    top: 92%;
  }
`;

const ImageWrap = styled(Relative)`
  max-height: 625px;

  img {
    margin: 0 auto;
    max-height: 625px;
    border-radius: 10px;
  }
`;

const QuoteWrap = styled(Box)`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 130px;

  @media (min-width: ${p => p.theme.screenM}) {
    max-width: 420px;
    transform: translateX(130px);
    margin-top: 40px;
  }
  @media (min-width: ${p => p.theme.screenL}) {
    max-width: 620px;
  }
`;

const IndexPage = ({ data, theme }) => {
  const testimonialList = data?.prismic?.data?.testimonialList || [];
  const hasMultipleItems = testimonialList.length > 1;
  const { slideIndex, onDrag: onCarouselDrag, onNextClick: onCarouselNextClick } = useCarousel(testimonialList.length);

  return (
    <Layout>
      <SEO path="" />
      
      <Section>
        <Container>
          <Flex flexWrap="wrap" alignItems="center" className="posRelative">
            <Box
              width={[1, 3 / 4, 1 / 2]}
              mx={[`auto`, null, 0]}
              mb={[20, null, 0]}
              mt={[null, null, 180, 0]}
            >
              <Image
                fluid={data.heroIllustration.childImageSharp.fluid}
                alt="illustration of a child footballer"
              />
            </Box>

            <Absolute
              position={[null, null, `absolute`]}
              top={(null, null, 20, 50)}
              right={0}
              width={[1, null, 3 / 4]}
              zIndex={5}
              maxWidth={[`none`, `none`, 500, 600, 680]}
            >
              <Heading as="h1">
                TopTekkers is the ultimate learning platform for young, aspiring
                soccer players
              </Heading>
            </Absolute>

            <Box
              width={[1, null, 1 / 2]}
              pt={[0, null, 210, 200]}
              pl={[0, null, 47]}
              maxWidth={520}
              zIndex={5}
            >
              <TextWithLink
                btnColor={theme.green}
                buttonText="Try it for free!"
                href="https://app.toptekkers.com"
              >
                TopTekkers is for players around 5-14 years old, who want to
                improve their skills and master the beautiful game! TopTekkers
                enables coaches and parents to help their young players be the
                best they can be by supporting their skill development.
              </TextWithLink>
            </Box>
          </Flex>
        </Container>

        <MaxWidthAbsolute>
          <Circle
            grow
            bgColor={theme.yellow}
            top={[-70, null, -90, -170]}
            right={[40, null, 100, 160]}
          />
          <Circle
            grow
            bgColor={theme.blue}
            bottom={[410, null, 40, null, null, 280]}
            left={[-30, null, -46, -83]}
          />
          <Circle
            grow
            bgColor={theme.pink}
            bottom={[340, null, 0]}
            right={[-30, null, -46, -83]}
          />
        </MaxWidthAbsolute>
      </Section>

      <Section>
        <Container>
          <Box width={[1, null, 3 / 4]}>
            <Heading as="h2" mb="0">
              Practice your Tekkers, take on challenges and track your scores!
            </Heading>
          </Box>

          <Box mt={[17, null, 0]} mb={[30, null, 0]} className="posRelative">
            <Image
              fluid={data.pitchIllustration.childImageSharp.fluid}
              alt="football pitch illustration with mobile app view"
            />

            <Circle
              growMid
              bgColor={theme.purple}
              top={`34%`}
              left={[-30, null, -42]}
            />
            <Circle
              grow
              bgColor={theme.orange}
              bottom={[-30, null, -46, -83]}
              right={[-30, null, -46, -83]}
            />
          </Box>

          <Box>
            <TextWithLink
              btnColor={theme.green}
              buttonText="Create an account"
              setWidth="472px"
              href="https://app.toptekkers.com"
            >
              The TopTekkers skill development videos will teach players all
              the important techniques they need to become a top player.
              Completing the challenges will help them practice in the most
              effective way and be the best they can be.
            </TextWithLink>
          </Box>
        </Container>
      </Section>

      {testimonialList.length > 0 && (
      <Section>
        <Container>
          <Heading
            as="h2"
            textAlign="center"
            mx="auto"
            mb={[`40px`, null, null, `60px`, `80px`]}
            maxWidth="578px"
            className="posRelative"
          >
          Used by the World’s best coaches
          </Heading>

          <Circle
            grow
            bgColor={theme.yellow}
            top="0"
            right={[-30, null, -46, -83]}
          />
        </Container>

        <Carousel
          slideIndex={slideIndex}
          onCarouselDrag={onCarouselDrag}
          hasMultipleItems={hasMultipleItems}
        >
          {testimonialList.map((entry, i) => {
            const { data: entryData } = entry.testimonial.document;
            const mainImage = entryData.main_image.localFile.childImageSharp.fluid;
            const profileData = { name: entryData.profile_name, title: entryData.profile_title, img: entryData.profile_image };

            return (
            // eslint-disable-next-line react/no-array-index-key
              <Container key={i}>
                <CarouselItem flexDirection="column">
                  <ImageWrap>
                    <Image fluid={mainImage} alt={entryData.main_image.alt} />

                    <Profile
                      maxWidth={[`100%`, null, `178px`]}
                      data={profileData}
                      mt={[`20px`, null, 0]}
                      alignItems={[`center`, null, `flex-start`]}
                      rowOnMob
                    />

                    {hasMultipleItems && (
                    <>
                      <Absolute
                        top={[`0`, null, `unset`]}
                        bottom={[`0`, null, `10%`]}
                        right={[`0`, null, `-46px`]}
                        zIndex="5"
                      >
                        <CarouselButton onClick={onCarouselNextClick} />
                      </Absolute>

                      <Counter
                        current={i + 1}
                        max={testimonialList.length}
                      />
                    </>
                    )}

                    <Circle
                      grow
                      bgColor={theme.pink}
                      bottom="45%"
                      left={[-30, null, -46, -83]}
                      hideOnMobile
                    />
                  </ImageWrap>

                  <QuoteWrap>
                    <CarouselQuote
                      quote={entryData.testimonial}
                      highlight={entryData.highlight}
                    />
                  </QuoteWrap>
                </CarouselItem>
              </Container>
            );
          })}
        </Carousel>
      </Section>
      )
}

      <Section>
        <Container>
          <Flex flexWrap="wrap" alignItems="center">
            <Box
              width={[1, null, 1 / 2]}
              mb={[20, null, 0]}
              className="posRelative"
            >
              <Image
                fluid={data.screenIllustration.childImageSharp.fluid}
                alt="Mobile app view of football skill and unlocked trophy."
              />

              <Circle
                growMid
                bgColor={theme.pink}
                bottom={`30%`}
                left={[-30, null, -42]}
              />
              <Circle
                grow
                bgColor={theme.yellow}
                top={[-6, null, -36, -60]}
                left={[`46%`, null, `38%`]}
              />
            </Box>

            <Box width={[1, null, 1 / 2]} pl={[0, null, 30, 40, 70]}>
              <Heading as="h2" setWidth="518px">
                Practice on the go with the TopTekkers app
              </Heading>

              <Text>
                Watch the videos, try the challenges, unlock trophies and
                view the training your coach has set you in the TopTekkers app.
              </Text>

              <AppStoreLinks />
            </Box>
          </Flex>
        </Container>
      </Section>

      <Section maxWidth="none" pb="0">
        <MaxWidthAbsolute>
          <Circle
            grow
            bgColor={theme.pink}
            top={[-28, null, -46, -76]}
            right={`10%`}
          />
          <Circle
            size="m"
            bgColor={theme.blue}
            top={80}
            left={`6%`}
            hideOnMobile
          />
          <Circle
            size="m"
            bgColor={theme.purple}
            bottom={50}
            right={`6%`}
            hideOnMobile
          />
          <Circle
            grow
            bgColor={theme.yellow}
            bottom={[-30, null, -46, -86]}
            left={[null, null, `12%`]}
            hideOnMobile
          />
          <Circle
            size="m"
            bgColor={theme.orange}
            bottom={`28%`}
            left={[null, null, `5%`, `19%`, `24%`]}
            hideOnMobile
          />
        </MaxWidthAbsolute>

        {/* Overlay */}
        <Absolute
          bg="rgba(255, 255, 255, 0.9)"
          borderTop="1px solid #EBEBEB"
          top="0"
          bottom="0"
          left="0"
          right="0"
        />

        {/* Content */}
        <Container pt={[60, null, null, 100]} pb={[60, null, 70]}>
          <Heading as="h3" maxWidth="785px" textAlign="center" mx="auto">
            Do you want to take the first step on a journey to being the best
            player you can be?
          </Heading>

          <Box px={0} my={[20, 55]} maxWidth={640} mx="auto" textAlign="center">
            <Video>
              <iframe
                title="TopTekkers promotional video"
                src="https://player.vimeo.com/video/365040395"
                width="100%"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              />
            </Video>
          </Box>

          <TextWithLink
            setCenter
            btnColor={theme.green}
            buttonText="Create an account"
            setWidth="579px"
            href="https://app.toptekkers.com"
          >
            Sign up to TopTekkers today, download the App and start practicing
            to become the best player you can be. It might even take you to the
            very top!
          </TextWithLink>
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    heroIllustration: file(relativePath: { eq: "images/Hero-Illustration.png" }) {
      childImageSharp {
        fluid(maxWidth: 680) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    pitchIllustration: file(relativePath: { eq: "images/Pitch-Illustration.png" }) {
      childImageSharp {
        fluid(maxWidth: 1098) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    screenIllustration: file(relativePath: { eq: "images/Screen-Illustration.png" }) {
      childImageSharp {
        fluid(maxWidth: 670) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    trophiesImg: file(relativePath: { eq: "images/trophiesImg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1098) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    prismic: prismicTestimonialList(data: {page: {eq: "Homepage"}}) {
      data {
        page
        testimonialList: testimonial_list {
          testimonial {
            document {
              ... on PrismicTestimonial {
                data {
                  main_image {
                    alt
                    localFile {
                      ...imageFragmentMax
                    }
                  }
                  highlight
                  testimonial
                  profile_image {
                    alt
                    localFile {
                      ...imageFragmentProfilePic
                    }
                  }
                  profile_name
                  profile_title
                }
              }
            }
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(IndexPage);
